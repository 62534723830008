@import url(https://fonts.googleapis.com/css?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One&display=swap);
#root {
  min-height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative; }

.brand {
  width: 10rem;
  height: 6rem;
  display: block; }
  @media only screen and (min-width: 100em) {
    .brand {
      width: 15rem;
      height: 9rem; } }
  .brand__img {
    width: 100%; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  height: 100%;
  font-size: 62.5%; }
  @media only screen and (max-width: 62.5em) {
    html {
      font-size: 55.5%; } }
  @media only screen and (max-width: 37.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 143.75em) {
    html {
      font-size: 90%; } }
  @media only screen and (min-width: 187.5em) {
    html {
      font-size: 110%; } }
  @media only screen and (min-width: 237.5em) {
    html {
      font-size: 120%; } }

body {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden; }

main {
  width: 90%;
  margin: 2.5rem auto;
  margin-bottom: 8rem; }
  @media only screen and (max-width: 62.5em) {
    main {
      width: 85%; } }
  @media only screen and (max-width: 31.25em) {
    main {
      width: 78%; } }
  @media only screen and (min-width: 87.5em) {
    main {
      width: 120rem; } }

::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #171a1d; }

::-webkit-scrollbar {
  width: 10px;
  background-color: #171a1d;
  border-radius: 3px; }

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #565f69; }

body {
  font-family: Staatliches, sans-serif; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.space-between {
  justify-content: space-between;
  margin: 0 3rem; }

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 47%;
    max-width: 50%;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px; } }

@media (max-width: 767px) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px; } }

.primary-header {
  margin: 0;
  font-size: 10.5rem;
  color: #5b6570;
  letter-spacing: 4px; }
  @media only screen and (max-width: 62.5em) {
    .primary-header {
      font-size: 8rem; } }
  @media only screen and (max-width: 44.375em) {
    .primary-header {
      font-size: 5rem;
      margin-bottom: 1rem; } }
  @media only screen and (max-width: 31.25em) {
    .primary-header span {
      display: block; } }

.secondary-header {
  font-size: 4rem;
  color: #fff;
  letter-spacing: 2px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.secondary-sub {
  font-family: Inconsolata, sans-serif;
  font-size: 1.6rem;
  color: #94a4b4;
  display: inline-block;
  margin: 1.2rem 0;
  line-height: 1.5;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }

.project {
  overflow: hidden;
  box-shadow: 0 0 10px #000;
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  visibility: visible; }
  .project__item {
    position: relative;
    height: 100%; }
  .project:hover .project__overlay {
    opacity: 1;
    visibility: visible; }
  .project:hover .project__img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .project__img {
    width: 100%;
    height: inherit;
    -webkit-transition: all 200ms;
    transition: all 200ms; }
    .project__img--modal {
      width: 100%;
      height: 100%; }
      @media only screen and (max-width: 45em) {
        .project__img--modal {
          object-fit: contain; } }
  .project__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.5));
    -webkit-transition: all 200ms;
    transition: all 200ms;
    opacity: 0;
    visibility: hidden;
    text-align: center; }
  .project__buttons {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%; }
    @media only screen and (max-width: 31.25em) {
      .project__buttons {
        bottom: 0.5rem; } }
  .project__button {
    display: inline-block;
    text-decoration: none;
    color: #22262a;
    font-family: Staatliches, sans-serif;
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin: 0 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 3px;
    padding: 0.5rem 2rem;
    cursor: pointer;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    outline: none; }
    @media only screen and (max-width: 31.25em) {
      .project__button {
        font-size: 1.6rem; } }
    .project__button:disabled, .project__button:disabled:hover {
      background-color: rgba(255, 255, 255, 0.4);
      cursor: default; }
    .project__button svg {
      margin-right: 0.5rem; }
    .project__button:hover, .project__button:focus, .project__button:active {
      background-color: white; }
    .project__button--modal {
      margin: 2rem 0.5rem 0 0.5rem;
      font-size: 1.4rem;
      padding: 0.5rem 1.5rem; }
  .project__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .project__background--show {
      opacity: 1;
      visibility: visible; }
  .project__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100rem;
    height: 50rem;
    background-color: #22262a;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
    box-shadow: 0 0 5px #000;
    opacity: 0;
    -webkit-transition: all 400ms 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 400ms 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform: translate(-50%, -50%) scale(0.25);
            transform: translate(-50%, -50%) scale(0.25); }
    .project__modal--show {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1); }
    @media only screen and (max-width: 56.25em) {
      .project__modal {
        width: 95%;
        min-height: 50rem; } }
    @media only screen and (max-width: 45em) {
      .project__modal {
        width: 43rem;
        height: auto; } }
    @media only screen and (max-width: 25em) {
      .project__modal {
        width: 38rem; } }
  .project__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background-color: transparent;
    border: none;
    color: #94a4b4;
    font-size: 3rem;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .project__close:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  .project__left {
    width: 60rem;
    height: 100%;
    display: inline-block; }
    @media only screen and (max-width: 56.25em) {
      .project__left {
        width: 55%; } }
    @media only screen and (max-width: 45em) {
      .project__left {
        width: 100%;
        height: auto; } }
  .project__right {
    width: calc(100% - 60.5rem);
    display: inline-block;
    vertical-align: top;
    padding: 1.5rem;
    color: #fff; }
    @media only screen and (max-width: 56.25em) {
      .project__right {
        width: 45%; } }
    @media only screen and (max-width: 45em) {
      .project__right {
        width: 100%; } }
  .project__label {
    font-size: 1.6rem;
    color: #94a4b4;
    letter-spacing: 1px; }
  .project__name {
    font-size: 3rem;
    letter-spacing: 1px;
    font-weight: lighter; }
  .project__technologies {
    list-style: none;
    margin-bottom: 2rem; }
  .project__technology {
    display: inline-block;
    margin: 0.5rem;
    border: 1px solid #171a1d;
    padding: 0.5rem;
    color: #94a4b4;
    font-weight: lighter;
    letter-spacing: 1px;
    font-size: 1.3rem; }
  .project__summary {
    margin-top: 1rem;
    font-family: Inconsolata, sans-serif;
    font-size: 1.4rem;
    line-height: 1.5;
    color: #94a4b4;
    overflow-y: scroll;
    height: 22rem;
    white-space: pre-line; }
    .project__summary::-webkit-scrollbar-track {
      background-color: #252a2e; }

.backdrop {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: default; }

.portfolio {
  margin-top: 4rem; }

.slide-left {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-5rem);
          transform: translateX(-5rem); }

.slide-right {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(5rem);
          transform: translateX(5rem); }

.contact {
  position: fixed;
  top: 50%;
  left: -7rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 700; }
  .contact svg {
    margin-bottom: -5px; }
  @media only screen and (min-width: 100em) {
    .contact {
      left: -12.5rem; } }
  .contact__list {
    list-style: none; }
  .contact__link:visited, .contact__link:link {
    display: block;
    color: #fff;
    font-size: 2.5rem;
    padding: 1rem;
    -webkit-transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-decoration: none; }
    @media only screen and (max-width: 31.25em) {
      .contact__link:visited, .contact__link:link {
        padding: 1rem; } }
    @media only screen and (min-width: 100em) {
      .contact__link:visited, .contact__link:link {
        padding: 1.5rem;
        font-size: 2.8rem; } }
  .contact__link--linkedin {
    background-color: #0077b5; }
  .contact__link--github {
    background-color: #333; }
  .contact__link--email {
    background-color: #6fc2b0; }
  .contact__link--twitter {
    background-color: #33a1f2; }
  .contact__link--fcc {
    background-color: #1b1b32; }
  .contact__link:hover, .contact__link:active, .contact__link:focus {
    -webkit-transform: translateX(6.4rem);
            transform: translateX(6.4rem); }
  .contact__label {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    margin-right: 1.2rem;
    width: 5.8rem;
    letter-spacing: 1px;
    text-align: right; }
    @media only screen and (min-width: 100em) {
      .contact__label {
        width: 10.8rem; } }

.footer {
  position: absolute;
  bottom: -7.5rem;
  left: 0;
  width: 100%;
  padding: 3rem;
  background: #171a1d;
  color: #94a4b4;
  font-size: 1.6rem;
  text-align: center;
  font-family: Inconsolata, sans-serif;
  border-top: 1px solid #22262a;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  text-align: center; }
  .footer__item {
    display: inline-block; }
    .footer__item:not(:last-child) {
      margin-right: 8rem; }
    @media only screen and (max-width: 50em) {
      .footer__item {
        display: block; }
        .footer__item:not(:last-child) {
          margin-right: 0;
          margin-bottom: 1rem; } }
  .footer__contact {
    display: inline-block; }
    .footer__contact:not(:last-child) {
      margin-right: 3rem; }
    @media only screen and (max-width: 50em) {
      .footer__contact:not(:last-child) {
        margin-right: 3rem;
        margin-bottom: 0.5rem; } }
  .footer__link:link, .footer__link:visited {
    color: #fff;
    text-decoration: none; }

.dark {
  color: #fff !important;
  background: #22262a;
  min-height: 100vh;
  padding: 2.5rem; }

.light {
  color: #22262a !important;
  background: #fff;
  min-height: 100vh;
  padding: 2.5rem; }
  .light .secondary-header {
    color: #22262a; }
  .light .secondary-sub {
    color: #22262a; }

.bg-dark {
  background: #24282a;
  border-radius: 3px; }

.bg-light {
  background: #ffffff;
  border-radius: 3px; }

.btn-clear {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer; }

.light-btn {
  color: #000;
  background: #aaa8a8; }

.light-btn:disabled {
  background: #292929;
  color: #4a4a4a; }

